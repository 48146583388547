<template>
  <div>
    <!-- Carousel -->
    <Carousel :banners="banners" />
    <!-- Menu -->
    <Menu :wallet="wallet" class="mb-4 d-lg-none" />
    <!-- All games -->
    <div class="" style="overflow-x: auto; position: relative">
      <div class="d-flex" ref="games">
        <div class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label p-1" @click="selectGame('')" v-bind:class="{'bg-white': selectedGame === '' }">
            <img src="/images/allgame2.png" alt="" class="h-100 w-100" />
          </div>
          <div class="font-size-sm gamename">All Games</div>
        </div>
        <div v-for="game in games" v-bind:key="game.alias" class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label" @click="selectGame(game.alias)" v-bind:class="{'bg-white': selectedGame === game.alias }">
            <img :src="game.img" alt="" class="h-75" />
          </div>
          <div class="font-size-sm gamename">{{ game.alias }}</div>
        </div>
      </div>
    </div>
    <!-- Banner 300px -->
    <div v-if="headads" class="banner rounded">
      <div class="image300" :style="headadsstyle">
        <a v-if="headads.link" :href="headads.link" target="_blank"></a>
      </div>
    </div>
    <!-- All matches -->
    <div class="card card-custom gutter-b card-stretch mt-4">
      <div class="pt-0">
        <div class="d-flex">
          <div class="d-none d-sm-block col-12 p-2 px-4">
            <span class="font-size-h4 text-white font-weight-bolder">การแข่งขัน</span>
            <span class="text-muted mt-3 font-weight-bold font-size-sm ml-4">ปัจจุบันและที่กำลังจะมาถึงเร็ว ๆ นี้</span>
          </div>
        </div>
      </div>
      <div class="pt-0">
        <div v-for="(m, i) in matches" v-bind:key="i">
          <div class="row pt-2 pb-1 matchhead font-size-sm">
            <div class="col-8 col-sm-8 d-inline-block d-sm-flex px-0">
              <div class="col-12 col-sm-6 px-2 d-flex">
                <div class="d-flex">
                  <div class="symbol symbol-20 symbol-dark mr-3 flex-shrink-0 text-center">
                    <div class="symbol-label">
                      <img v-if="m.tourimg" :src="m.tourimg" alt="" class="h-100 w-100" />
                    </div>
                  </div>
                  <div class="">{{ m.tourname }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6 px-2">
                <router-link :to="`/matches/${m.no}`" class="text-white">{{ m.name }}</router-link>
              </div>
            </div>
            <div class="font-size-sm date col-4 col-sm-4 px-2">
              <span class="mb-2 mr-2">{{ date(m.plan) }}</span>
              <span v-if="m.forfeit"><b-badge variant="danger" class="status">Forfeit</b-badge></span>
              <span v-else-if="m.ended"><b-badge variant="primary" class="status">Ended</b-badge></span>
              <span v-else-if="playing(m)"><b-badge variant="info" class="status">Playing</b-badge></span>
            </div>
          </div>
          <div class="px-0 pt-4">
            <!-- Versus mode -->
            <b-row align-v="center" v-if="modevs(m)" class="mb-1">
              <b-col :cols="3" class="text-right font-size-h1 display-5 font-weight-bolder pr-10">{{ m.teams[0].score }}</b-col>
              <b-col :cols="6" class="">
                <b-row align-h="between" align-v="center">
                  <div class="symbol symbol-20 symbol-lg-30">
                    <div class="symbol-label bg-transparent">
                      <img v-if="m.teams[0].img" :src="m.teams[0].img" alt="" class="h-100 w-100" />
                    </div>
                  </div>
                  <b-col cols="auto" class="text-center d-flex font-size-sm">
                    <div>{{ m.teams[0].name }}</div>
                    <div class="px-2">vs</div>
                    <div>{{ m.teams[1].name }}</div>
                  </b-col>
                  <div class="symbol symbol-20 symbol-lg-30">
                    <div class="symbol-label bg-transparent">
                      <img v-if="m.teams[1].img" :src="m.teams[1].img" alt="" class="h-100 w-100" />
                    </div>
                  </div>
                </b-row>
              </b-col>
              <b-col :cols="3" class="font-size-h1 display-5 font-weight-bolder pl-10">{{ m.teams[1].score }}</b-col>
            </b-row>
            <!-- Free for all mode -->
            <div v-if="modeffa(m)" class="mb-1">
              <b-row>
                <!-- First half -->
                <b-col :cols="12" :md="6">
                  <b-row align-v="center" v-for="(t, i) in firsthalf(m)" v-bind:key="i">
                    <b-col :cols="5" class="text-right font-size-h4 display-4 font-weight-bolder">
                      {{ t.score }}
                    </b-col>
                    <b-col :cols="1" class="text-center">
                      <div class="symbol symbol-20">
                        <div class="symbol-label bg-transparent">
                          <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                        </div>
                      </div>
                    </b-col>
                    <b-col :cols="6" class="font-size-h5">
                      {{ t.name }}
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Second half -->
                <b-col :cols="12" :md="6">
                  <b-row align-v="center" v-for="(t, i) in secondhalf(m)" v-bind:key="i">
                    <b-col :cols="5" class="text-right font-size-h4 display-4 font-weight-bolder">
                      {{ t.score }}
                    </b-col>
                    <b-col :cols="1" class="text-center">
                      <div class="symbol symbol-20">
                        <div class="symbol-label bg-transparent">
                          <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                        </div>
                      </div>
                    </b-col>
                    <b-col :cols="6" class="font-size-h5">
                      {{ t.name }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <!-- No teams -->
            <b-row v-if="noteams(m)" class="mb-1 px-4">
              <b-col :cols="12" class="font-size-sm">
                <b-badge v-for="(c, j) in m.noteams" v-bind:key="j" class="mr-4 mb-2" :variant="chk(c)">{{ c.name }}</b-badge>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="loading" class="row mt-4">
          <div class="col-12 text-center">กำลังโหลด</div>
        </div>
        <div v-if="nomatch" class="row mt-4">
          <div class="col-12 text-center">ยังไม่มีให้ทายผลการแข่งขัน</div>
        </div>
        <!-- Pagination -->
        <div v-if="count > 20" class="mt-6 d-flex justify-content-center">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="20"
            @change="selectPage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Carousel from "@/view/content/Carousel.vue";
import Menu from "./Menu";

export default {
  name: "dashboard",
  components: {
    Carousel,
    Menu
  },
  mounted() {
    this.recvData()
    this.recvFixture()
  },
  methods: {
    link(game) {
      return `/wallets/${this.wallet}/games/${game.alias}`
    },
    recvData() {
      this.axios.get(`/wallets/${this.wallet}/games`).then(
        ({ data }) => {
          this.games = data.games
          this.banners = data.banners || []
          this.headads = data.headads || null
          this.pageads = data.pageads || {}
        }
      )
    },
    modevs(m) {
      return m.teams != null && m.teams.length === 2
    },
    modeffa(m) {
      return m.teams != null && m.teams.length > 2
    },
    chk(c) {
      return c.checked != null && c.checked ? 'success' : 'dark text-dark-50'
    },
    noteams(m) {
      return m.noteams != null && m.noteams.length > 0
    },
    date(time) {
      return dayjs(time).format('DD/MM/YY HH:mm')
    },
    playing(m) {
      return dayjs() > dayjs(m.plan)
    },
    recvFixture() {
      this.loading = true
      var opt = {
        params: {}
      }

      if (this.selectedGame !== '') {
        opt.params.game = this.selectedGame
      }

      if (this.page > 1) {
        opt.params.page = this.page
      }

      this.axios.get(`/wallets/${this.wallet}/fixture`, opt).then(
        ({ data }) => {
          this.matches = data.matches
          this.count = data.count
        }
      ).finally(() => {
        this.loading = false
      })
    },
    firsthalf (m) {
      var num = (m.teams || []).length
      if (num < 5) {
        return m.teams
      }

      if (num === 0) {
        return []
      }

      var num = Math.ceil(num * 0.5)
      return m.teams.slice(0, num)
    },
    secondhalf (m) {
      var num = (m.teams || []).length
      if (num < 5) {
        return []
      }

      var half = Math.ceil(num * 0.5)
      return m.teams.slice(half, num)
    },
    selectGame (game) {
      this.selectedGame = game
      this.page = 1
      this.recvFixture()
    },
    selectPage (page) {
      this.page = page
      this.recvFixture()
    },
    pageadshow (row) {
      return this.pageads != null && (this.pageads[`p${row}0`] != null || this.pageads[`p${row}1`] != null || this.pageads[`p${row}2`] != null)
    },
    pageadstyle (row, pos) {
      if (this.pageads != null && this.pageads[`p${row}${pos}`] != null) {
        return {
          'background-image': `url(${this.pageads[`p${row}${pos}`].img})`
        }
      }

      return {}
    }
  },
  computed: {
    wallet() {
      return this.$route.params.wallet
    },
    nomatch() {
      return this.loading === false && this.matches.length === 0
    },
    headadsstyle () {
      if (this.headads) {
        return {
          'background-image': `url(${this.headads.img})`
        }
      }

      return {}
    }
  },
  data() {
    return {
      games: [],
      matches: [],
      banners: [],
      headads: null,
      pageads: [],
      loading: true,
      count: 0,
      page: 1,
      selectedGame: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.game {
  cursor: pointer;
}

.gamename {
  width: 50px;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .gamename {
    width: 75px;
    font-size: 0.925rem;
  }
}

.active {
  background-color: #FFFFFF;
}

.date {
  text-align: right;
}

@media (max-width: 575px) {
  .date {
    text-align: left;
  }
}

.matchhead {
  background-color: #3b445a;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #14171D;

  .symbol-15 {
    width: 15px;
    height: 15px;
  }
}

.status {
  width: 50px;
}

.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  .image300 {
    // height: 300px;
    padding-top: 23.26%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: top;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.banner2 {
  border-top: 1px solid #14171D;

  .image100 {
    height: 100px;
    // padding-top: 7.76%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: center;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>